import { useEffect, useRef, useState } from "react";
import { Language } from "../lib/types";
import { useLocale } from "../hooks/use-locale";

function useChatbotInitialization() {
  const [loading, setLoading] = useState<boolean>(false);
  const chatbotInitialized = useRef<boolean>(false);

  const { lang } = useLocale();

  const chatbotLanguage = useRef(lang === Language.SV ? Language.SE : lang);

  useEffect(() => {
    if (chatbotInitialized.current) {
      return;
    }

    setLoading(true);
    chatbotInitialized.current = true;

    const xeniooScript = initializeXenioo(chatbotLanguage.current, () => {
      setLoading(false);
    });

    return () => {
      if (xeniooScript && xeniooScript.parentNode) {
        document.body.removeChild(xeniooScript);
      }
      const xeniooElements = document.querySelectorAll(
        '[data-id="xenioo"], [id="xenioo-container"], [id="xenioo-chat-widget"]'
      );

      xeniooElements.forEach((el) => {
        if (el && el.parentNode) {
          el.remove();
        }
      });
    };
  }, []);

  return { loading };
}

function ChatbotContainer() {
  const { t } = useLocale();
  const { loading } = useChatbotInitialization();

  return (
    <div className="mt-10 flex-1" id="xenioo-chat-container">
      {loading && (
        <div className="h-full w-full flex items-center justify-center">
          <p className="font-medium">{t("loading")}</p>
        </div>
      )}
      <div id="xenioowebchat" style={{ height: "100%", width: "100%" }} />
    </div>
  );
}

export default ChatbotContainer;

function initializeXenioo(
  languageOnInit: Language,
  onLoadCallback: () => void
) {
  if (
    document.querySelector(
      'script[src="https://res.xenioo.com/plugin/xenioo.js"]'
    )
  ) {
    console.warn("Xenioo script is already added.");
    onLoadCallback();
    return;
  }

  const xeniooScript = document.createElement("script");
  xeniooScript.setAttribute("data-id", "xenioo");
  xeniooScript.setAttribute("data-node", "cm91dGluZy54ZW5pb28uY29t");
  xeniooScript.src = "https://res.xenioo.com/plugin/xenioo.js";

  xeniooScript.onerror = () => {
    console.error("Failed to load the Xenioo script.");
    onLoadCallback();
  };

  xeniooScript.onload = () => {
    if (typeof xenioowebchat === "undefined") {
      console.error("Xenioo web chat object is not available.");
      return;
    }

    xenioowebchat.Start("4if1fphwUQBFgFY6YLY5EiWdKWI8ol7t", {
      variables: [
        {Name: "initial_language", Value: languageOnInit.toString()},
      ],
      loadError: () => {
        //handleChatbotLoadingError();
      },
    });
    onLoadCallback();
  };

  document.body.appendChild(xeniooScript);
  return xeniooScript;
}
