import { getLangFromUrl, useTranslations } from "../i18n/utils";

export function useLocale() {
  const lang = getLangFromUrl(new URL(window.location.href));

  return {
    lang,
    t: useTranslations(lang),
  };
}
