interface Props {
  footerText: string;
}
function Footer({ footerText }: Props) {
  return (
    <footer className="border-t-2 border-autoliittoShadow">
      <div className="bg-autoliittoYellow justify-center p-4 flex items-center space-x-3">
        <p className="font-sans text-lg ">{footerText}</p>
        <a href="https://www.autoliitto.fi/">
          <img
            src="autoliitto_logo.png"
            alt="Autoliitto logo"
            className="h-10"
          />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
