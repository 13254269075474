import ChatbotContainer from "./Components/ChatbotContainer";
import { useEffect, useState } from "react";
import { Language } from "./lib/types";
import {
  DuetButton,
  DuetCard,
  DuetParagraph,
  DuetHeading,
  DuetLayout,
} from "@duetds/react";
import LanguageSelectDuetHeader from "./Components/LanguageSelectDuetHeader.tsx";
import { useLocation } from "react-router-dom";
import Footer from "./Components/Footer.tsx";
import { useLocale } from "./hooks/use-locale.tsx";

function Content() {
  const availableLanguages: { name: string; type: Language }[] = [
    { name: "Suomeksi", type: Language.FI },
    { name: "På Svenska", type: Language.SV },
    { name: "In English", type: Language.EN },
  ];

  const { t } = useLocale();

  const [botEnabled, setBotEnabled] = useState(false);
  const [failedToLoadChatbot, setFailedToLoadChatbot] = useState(false);

  let location = useLocation();

  useEffect(() => {
    setBotEnabled(false);
    setFailedToLoadChatbot(false);
  }, [location]);

  return (
    <div className="h-dvh w-dvw bg-[#f3f9fc] overflow-hidden flex flex-col">
      <LanguageSelectDuetHeader availableLanguages={availableLanguages} />
      {!botEnabled && (
        <div className="flex-1 flex-col">
          <DuetLayout>
            <div slot="main">
              <DuetCard>
                <DuetHeading level="h1">{t("welcome")}</DuetHeading>
                {!failedToLoadChatbot && (
                  <DuetParagraph id="content">{t("greeting")}</DuetParagraph>
                )}
                {failedToLoadChatbot && (
                  <DuetParagraph id="content">{t("error")}</DuetParagraph>
                )}
                {!botEnabled && (
                  <div>
                    <DuetButton
                      variation="primary"
                      onClick={() => setBotEnabled(true)}
                    >
                      {t("start")}
                    </DuetButton>
                  </div>
                )}
              </DuetCard>
            </div>
          </DuetLayout>
        </div>
      )}

      {botEnabled && <ChatbotContainer />}
      <div className="w-screen">
        <Footer footerText={t("poweredBy")} />
      </div>
    </div>
  );
}

export default Content;
