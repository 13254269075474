import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Content from "./Content.tsx";
import { Language } from "./lib/types.ts";
import { translations } from "./i18n/translations.ts";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="/:lang/*"
        loader={({ params }) => {
          const lang = params.lang;
          const { origin } = window.location;

          const supportedLanguages = Object.values(Language) as string[];

          if (!supportedLanguages.includes(lang!)) {
            window.location.href = `${origin}/${supportedLanguages[0]}`;
            return {};
          }

          document.documentElement.setAttribute("lang", lang!);

          document.title =
            translations[lang as keyof typeof translations]["pageTitle"];

          return {};
        }}
        element={<Content />}
      />
      <Route
        path="*"
        loader={() => {
          const supportedLanguages = Object.values(Language) as string[];
          window.location.href = `${origin}/${supportedLanguages[0]}`;
          return {};
        }}
      />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
