export const defaultLang = "fi";

export const translations = {
  fi: {
    greeting: "Hei, tervetuloa LähiTapiolan Liikkumisen hätäpalveluun!",
    error:
      "Jotain meni ikävä kyllä pieleen. Kokeile hetken kuluttua uudestaan.",
    start: "Aloita",
    welcome: "Tervetuloa!",
    poweredBy: "Palvelun tarjoaa",
    pageTitle: "Liikkumisen Hätäpalvelu",
    loading: "Sivua ladataan, odota hetki...",
  },
  en: {
    greeting: "Hello and welcome!",
    error:
      "Unfortunately something went wrong. Please try again after a short while.",
    start: "Begin",
    welcome: "Welcome!",
    poweredBy: "Powered by",
    pageTitle: "Emergency Service",
    loading: "The page is loading, please wait a moment...",
  },
  sv: {
    greeting: "Hej, välkommen!",
    error: "Tyvärr gick något fel. Försök igen om en stund.",
    start: "Börja",
    welcome: "Välkommen!",
    poweredBy: "Drivs av",
    pageTitle: "Larmtjänsten",
    loading: "Sidan laddas, vänligen vänta ett ögonblick...",
  },
} as const;
