import { DuetHeader } from "@duetds/react";
import { Language } from "../lib/types.ts";
import { useLocale } from "../hooks/use-locale.tsx";

interface Props {
  availableLanguages: { name: string; type: Language }[];
}

interface DuetHeaderLanguageItem {
  label: string;
  language: Language;
  country: string;
}

function LanguageSelectDuetHeader({ availableLanguages }: Props) {
  const { lang } = useLocale();

  const handleLanguageSelect = (event: any) => {
    const selectedLanguage = event.detail.data.language;
    if (selectedLanguage === lang) {
      return;
    }

    // here we have to do hard navigation instead of
    // using react routers navigate function
    // to ensure that html lang tags are correctly detected by duet components
    document.documentElement.setAttribute("lang", selectedLanguage);

    const currentUrl = new URL(window.location.href);

    const pathSegments = currentUrl.pathname.split("/");
    pathSegments[1] = selectedLanguage;

    currentUrl.pathname = pathSegments.join("/");

    window.location.href = currentUrl.toString();
  };

  const languageItems = availableLanguages.map((language) => {
    const languageItem: DuetHeaderLanguageItem = {
      label: language.name,
      language: language.type,
      country: language.type,
    };

    return languageItem;
  });

  return (
    <DuetHeader
      languageItems={languageItems}
      onDuetLanguageSelect={handleLanguageSelect}
    />
  );
}

export default LanguageSelectDuetHeader;
